import { useToast } from '@chakra-ui/toast';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FullPageLoader } from '../components/FullPageLoader';
import { setupAxios } from '../setupAxios';
import {
	getRefreshToken,
	removeAccessToken,
	removeRefreshToken,
	setAccessToken,
} from '../utils/authFunctions';
import { logout } from '../utils/logoutFunction';
import { PageRoutes } from './PageRoutes';

function App() {
	const [loading, setLoading] = useState(true);
	const toast = useToast();

	setupAxios(axios, toast);

	useEffect(() => {
		const refreshToken = async () => {
			try {
				let token = getRefreshToken();
				if (token) {
					const result = await axios.post(
						`${process.env.REACT_APP_UMS_BASE_URL}/admin/token`,
						{ refreshToken: getRefreshToken() }
					);
					setAccessToken(result.data.data.token);
				} else {
					removeAccessToken();
					removeRefreshToken();
					logout();
				}
			} catch (err: any) {
				toast({
					description: err.response?.data?.message || 'An error occurred',
					status: 'error',
				});
				removeAccessToken();
				removeRefreshToken();
				logout();
			}
			setLoading(false);
		};
		refreshToken();
	}, [toast]);

	if (loading) {
		return <FullPageLoader />;
	}

	return <PageRoutes />;
}

export default App;
