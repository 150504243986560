import { FiHome /*FiMap*/ } from 'react-icons/fi';
import { ModuleListType } from './types';

export const Modules: ModuleListType[] = [
	{
		name: 'Homepage Builder',
		description: 'Create new templates and manage the nesto homepage.',
		key: 'hbs',
		icon: FiHome,
		color: 'red',
	},
	// {
	// 	name: 'Zone Management',
	// 	description: 'Manage and get details of timeslots and orders.',
	// 	key: 'zms',
	// 	icon: FiMap,
	// 	color: 'blue',
	// },
];
