import { Button, IconButton } from '@chakra-ui/button';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { HStack, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import {
	FiChevronLeft,
	FiChevronRight,
	FiEdit,
	FiEye,
	FiPlus,
} from 'react-icons/fi';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { customScrollCss } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
	addPage,
	addPageList,
	changePageDetails,
} from '../../redux/reducers/currentPageSlice';
import {
	setLayout,
	startLoading,
	stopLoading,
} from '../../redux/reducers/layoutSlice';
import { setStores } from '../../redux/reducers/storeSlice';
import {
	addPageToContent,
	clearContent,
	setIsPublished,
} from '../../redux/reducers/subTemplateSlice';
import {
	PageList,
	PageType,
	SubTemplateContentType,
	SubTemplatePageType,
	TemplateListType,
} from '../../types/types';
import { AddNewPageModal } from './partials/AddNewPageModal';
import { ComponentDrawer } from './partials/ComponentDrawer';
import { ConfirmPublishModal } from './partials/ConfirmPublishModal';
import { EditPageModal } from './partials/EditPageModal';
import { InputDrawer } from './partials/InputDrawer';
import { SubTemplateList } from './partials/SubTemplateList';

interface TemplateEditorProps {}

interface PageNameList {
	name: string;
	_id: string;
}

export const TemplateEditor: React.FC<TemplateEditorProps> = () => {
	const dispatch = useAppDispatch();
	const { pageId, templateId } = useParams();
	const toast = useToast();
	const [details, setDetails] = useState({
		pageName: '',
		templateName: '',
	});
	const [template, setTemplate] = useState<TemplateListType>();
	const [pageNameList, setPageNameList] = useState<PageNameList[]>([]);
	const [currentModal, setCurrentModal] = useState<
		'editPage' | 'addPage' | 'confirmPublish' | null
	>(null);
	const { isPublished } = useAppSelector((state) => state.subTemplate);
	const { stores } = useAppSelector((state) => state.store);
	const [publishLoading, setPublishLoading] = useState(false);
	const [previewLoading, setPreviewLoading] = useState(false);
	useEffect(() => {
		dispatch(setLayout({ layout: 'editor', isOpenSidenav: false }));
		return () => {
			dispatch(setLayout({ layout: 'dashboard', isOpenSidenav: true }));
		};
	}, [dispatch]);

	useEffect(() => {
		const isPageNew = (pg: PageType) => {
			let flag = true;
			template?.pages.forEach((p) => {
				if (p._id === pg._id) {
					flag = false;
				}
			});
			return flag;
		};
		const getData = async () => {
			dispatch(startLoading());
			try {
				const pageResult = await axios.get(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/page/${pageId}`
				);
				let templateData;
				if (templateId === template?._id && !isPageNew(pageResult.data.data)) {
					templateData = template;
				} else {
					const templateResult = await axios.get(
						`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/template/${templateId}`
					);
					templateData = templateResult.data.data;
					setTemplate(templateData);
					dispatch(
						setIsPublished({
							isPublished: !!templateData.isPublish,
						})
					);
				}
				let pageList: PageList[] = [];
				templateData.pages.forEach((pg: SubTemplatePageType) => {
					pageList.push({ _id: pg._id, name: pg.name });
					if (pg._id === pageId) {
						let temp = pageResult.data?.data?.content?.map(
							(con: SubTemplateContentType) => {
								return { ...con.subTemplate, position: con.position };
							}
						);
						dispatch(addPageToContent({ page: temp }));
					}
				});
				setDetails({
					pageName: pageResult.data.data.name,
					templateName: templateData.name,
				});
				dispatch(addPageList(pageList));
				dispatch(addPage({ page: pageResult.data.data }));
			} catch (err: any) {
				toast({
					position: 'top',
					status: 'error',
					description: err?.response?.data?.message || 'An error occurred',
				});
			}
			dispatch(stopLoading());
		};
		getData();
	}, [templateId, pageId, dispatch, toast, template]);

	useEffect(() => {
		return () => {
			dispatch(clearContent());
		};
	}, [dispatch]);

	useEffect(() => {
		const getData = async () => {
			const result = await axios.get(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/store`,
				{
					params: {
						page: 1,
						limit: 30,
					},
				}
			);
			dispatch(setStores({ stores: result.data.data }));
		};
		if (stores.length === 0) {
			getData();
		}
	}, [dispatch, stores]);

	useEffect(() => {
		if (template && template.pages.length > 0) {
			setPageNameList(
				template.pages.map((pg) => {
					return { _id: pg._id!, name: pg.name };
				})
			);
		}
	}, [template]);

	const updatePageFunction = (pg: PageType) => {
		let newPageList: PageList[] = [];
		newPageList = pageNameList.map((p) => {
			if (p._id === pg._id) {
				return { ...p, name: pg.name };
			}
			return p;
		});
		setPageNameList(newPageList);
		dispatch(addPageList(newPageList));
		dispatch(changePageDetails({ newPage: pg }));
	};

	const onClose = () => {
		setCurrentModal(null);
	};

	const onOpen = (s: 'editPage' | 'addPage' | 'confirmPublish') => {
		setCurrentModal(s);
	};

	const publishTemplate = async () => {
		setPublishLoading(true);
		try {
			await axios.post(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/template/publish`,
				{ id: templateId }
			);
			toast({
				description: 'Template published successfully',
				status: 'success',
			});
			dispatch(setIsPublished({ isPublished: true }));
		} catch (err: any) {
			toast({
				description: err.response?.data?.message || 'An error occurred',
				status: 'error',
			});
		}
		setPublishLoading(false);
	};

	const pageListRef = useRef<HTMLDivElement>(null);

	const scrollToLeft = () => {
		if (pageListRef.current?.scrollLeft !== undefined) {
			pageListRef.current.scrollLeft -= 100;
		}
	};

	const scrollToRight = () => {
		if (pageListRef.current?.scrollLeft !== undefined) {
			pageListRef.current.scrollLeft += 100;
		}
	};

	const onPreview = async (templateId: string) => {
		try {
			setPreviewLoading(true);
			const result = await axios.post(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/template-demo`,
				{ templateId }
			);

			let url = new URL(`${process.env.REACT_APP_ECOMM_BASE_URL}`);
			console.log('url', url);
			url.searchParams.append('isDemo', 'true');
			url.searchParams.append(
				process.env.REACT_APP_JWT_NAME!,
				result.data.data[process.env.REACT_APP_JWT_NAME!]
			);
			url.searchParams.append(
				process.env.REACT_APP_TEMPLATEID_NAME!,
				result.data.data[process.env.REACT_APP_TEMPLATEID_NAME!]
			);
			console.log('url params', url.searchParams);
			console.log('url search', url.search);
			window.open(url, '_blank')?.focus();
		} catch (err: any) {
			toast({
				description: err?.response?.data?.message || 'An error occurred',
				status: 'error',
			});
		}
		setPreviewLoading(false);
	};

	return (
		<>
			<EditPageModal
				isOpen={currentModal === 'editPage'}
				onClose={onClose}
				updateFunction={updatePageFunction}
			/>
			<AddNewPageModal
				isOpen={currentModal === 'addPage'}
				onClose={onClose}
				templateId={template?._id || ''}
			/>
			<ConfirmPublishModal
				isOpen={currentModal === 'confirmPublish'}
				onClose={onClose}
				submitFunction={publishTemplate}
			/>
			<Box p='2' pt='0' bg='gray.100' position='relative'>
				<Box
					bg='gray.100'
					h='2'
					w='100%'
					zIndex={10}
					position='sticky'
					top='0'
				></Box>
				<Flex
					mb='2'
					bg='white'
					position='sticky'
					top='8px'
					zIndex={10}
					py='2'
					px='4'
					alignItems='center'
					justifyContent='space-between'
				>
					<Text fontWeight='bold' fontSize='lg'>
						{details.templateName}
					</Text>
					<Flex>
						<Button
							colorScheme='primary'
							variant='outline'
							size='sm'
							onClick={() => onPreview(templateId || '')}
							loadingText='loading preview...'
							isLoading={previewLoading}
							mr='2'
							leftIcon={<FiEye />}
						>
							Preview Template
						</Button>
						<Button
							colorScheme='green'
							size='sm'
							disabled={isPublished}
							onClick={() => onOpen('confirmPublish')}
							isLoading={publishLoading}
							loadingText='Publishing...'
						>
							Publish Template
						</Button>
					</Flex>
				</Flex>
				<HStack spacing={2} height='100%' alignItems='start' maxWidth='100%'>
					<ComponentDrawer />
					<Box flexGrow='1' height='100%' rounded='md' overflowX='auto'>
						<Flex alignItems='center' justifyContent='space-between' pt='2'>
							<Box overflowX='hidden' position='relative' role='group'>
								<IconButton
									aria-label='scroll left'
									icon={<FiChevronLeft />}
									position='absolute'
									top='1'
									left='0'
									size='sm'
									zIndex='10'
									bg='white'
									border='1px solid'
									borderColor='gray.200'
									display='none'
									_hover={{
										bg: 'white',
										borderColor: 'primary.200',
										color: 'primary.600',
									}}
									_groupHover={{ display: 'flex' }}
									onClick={scrollToLeft}
								/>
								<IconButton
									aria-label='scroll right'
									icon={<FiChevronRight />}
									position='absolute'
									top='1'
									right='2'
									size='sm'
									zIndex='10'
									bg='white'
									border='1px solid'
									borderColor='gray.200'
									display='none'
									_hover={{
										bg: 'white',
										borderColor: 'primary.200',
										color: 'primary.600',
									}}
									_groupHover={{ display: 'flex' }}
									onClick={scrollToRight}
								/>
								<HStack
									overflowX='auto'
									css={customScrollCss}
									mr='5'
									ref={pageListRef}
								>
									{pageNameList.map((pg) => {
										return (
											<Button
												bg={pageId === pg._id ? 'white' : 'transparent'}
												as={Link}
												to={`/editor/${templateId}/${pg._id}`}
												color={pageId === pg._id ? 'primary.400' : 'gray.500'}
												rounded='none'
												roundedTop='md'
												justifyContent='start'
												_hover={{ bg: 'white', color: 'primary.400' }}
												_focus={{ outline: 'none' }}
												flexShrink='0'
												key={pg._id}
											>
												{pg.name}
											</Button>
										);
									})}
								</HStack>
							</Box>
							<Flex pr='1'>
								<Button
									leftIcon={<FiPlus />}
									size='sm'
									colorScheme='green'
									onClick={() => onOpen('addPage')}
									mr='2'
									variant='outline'
								>
									Add page
								</Button>
								<Button
									leftIcon={<FiEdit />}
									size='sm'
									colorScheme='green'
									onClick={() => onOpen('editPage')}
									variant='outline'
								>
									Edit page
								</Button>
							</Flex>
						</Flex>
						<Box bg='white' p='2'>
							<Box
								height='100%'
								p='6'
								border='1px solid'
								borderColor='gray.100'
							>
								<SubTemplateList />
							</Box>
						</Box>
					</Box>
					<InputDrawer />
				</HStack>
			</Box>
		</>
	);
};
