let accessToken = '';

export const getAccessToken = () => {
	return accessToken;
};

export const setAccessToken = (token: string) => {
	accessToken = token;
};

export const removeAccessToken = () => {
	accessToken = '';
};

export const getRefreshToken = () => {
	return localStorage.getItem('refreshToken');
};

export const setRefreshToken = (token: string) => {
	return localStorage.setItem('refreshToken', token);
};

export const removeRefreshToken = () => {
	return localStorage.removeItem('refreshToken');
};
