import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FullPageLoader } from '../components/FullPageLoader';
import { setupAxios } from '../setupAxios';
import {
	getRefreshToken,
	setAccessToken,
	removeRefreshToken,
} from '../utils/authFunctions';
import { PageRoutes } from './PageRoutes';
import { useAppDispatch } from './redux/hooks';
import { authLogin, authLogout } from './redux/reducers/authSlice';

interface AppProps {}

export const App: React.FC<AppProps> = () => {
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(true);
	const toast = useToast();

	setupAxios(axios, toast);

	useEffect(() => {
		const refreshToken = async () => {
			try {
				let token = getRefreshToken();
				if (token) {
					const result = await axios.post(
						`${process.env.REACT_APP_UMS_BASE_URL}/admin/token`,
						{ refreshToken: getRefreshToken() }
					);
					setAccessToken(result.data.data.token);
					dispatch(authLogin());
				} else {
					removeRefreshToken();
					dispatch(authLogout());
				}
			} catch (err: any) {
				toast({
					description: err.response?.data?.message || 'An error occurred',
					status: 'error',
				});
				removeRefreshToken();
				dispatch(authLogout());
			}
			setTimeout(() => setLoading(false), 200);
		};
		refreshToken();
	}, [toast, dispatch]);

	if (loading) {
		return <FullPageLoader />;
	}

	return <PageRoutes />;
};
