import {
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Alert,
	AlertIcon,
	Grid,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	ModalFooter,
	Button,
	Select,
} from '@chakra-ui/react';
import axios from 'axios';
import { AsyncSelect } from 'chakra-react-select';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

interface AddTimeslotModalFormProps {
	url: string;
	isOpen: boolean;
	onClose: () => void;
	refetchData: () => void;
}

export const AddTimeslotModalForm: React.FC<AddTimeslotModalFormProps> = ({
	url,
	isOpen,
	onClose,
	refetchData,
}) => {
	const initialValues = {
		name: '',
		zone_id: '',
		active_from: '',
		active_to: '',
		customer_group: '',
		status: '',
		sort_order: '',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required'),
		zone_id: Yup.number().required('Zone is required'),
		active_from: Yup.string().required('Active from date is required'),
		active_to: Yup.string().required('Active to date is required'),
		customer_group: Yup.string()
			.oneOf(['general', 'other'])
			.required('Customer group is required'),
		status: Yup.number().oneOf([1, 0]).required('Status is required'),
		sort_order: Yup.number().required('Sort order is required'),
	});

	const toast = useToast();
	const [zoneKeyword, setZoneKeyword] = useState('');
	const [selectedZone, setSelectedZone] = useState(null);

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		onSubmit: async (values, { setStatus }) => {
			try {
				await axios.post(`${url}`, {
					...values,
					status: !!parseInt(values.status),
				});
				toast({
					description: 'Item added successfully',
					status: 'success',
				});
				resetAndCloseAddModal();
				refetchData();
			} catch (err: any) {
				if (err.response.status !== 401 && err.response.status !== 420) {
					setStatus(err.response.data.message || 'An error occurred');
				}
			}
		},
	});

	const loadZoneOptions = () => {
		return axios
			.get(`${process.env.REACT_APP_ZMS_BASE_URL}/zone`, {
				params: {
					pagination: { page: 1, limit: 30 },
					filter: { keyword: zoneKeyword },
				},
			})
			.then((res) => res.data.data)
			.catch((err) => []);
	};

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
			size='2xl'
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Add Timeslot</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<Grid templateColumns='repeat(2, 1fr)' gap={4}>
							<FormControl isInvalid={!!formik.errors.name} mb='4'>
								<FormLabel>Name</FormLabel>
								<Input {...formik.getFieldProps('name')} />
								<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!formik.errors.zone_id} mb='4'>
								<FormLabel>Zone</FormLabel>
								<AsyncSelect
									cacheOptions
									defaultOptions
									getOptionLabel={(option: any) => option.name}
									getOptionValue={(option: any) => option.id}
									loadOptions={loadZoneOptions}
									onInputChange={(v: any) => setZoneKeyword(v)}
									value={selectedZone}
									onChange={(v: any) => {
										setSelectedZone(v);
										formik.setFieldValue('zone_id', v.id);
									}}
								/>
								<FormErrorMessage>{formik.errors.zone_id}</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!formik.errors.active_from} mb='4'>
								<FormLabel>Active From</FormLabel>
								<Input {...formik.getFieldProps('active_from')} type='date' />
								<FormErrorMessage>{formik.errors.active_from}</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!formik.errors.active_to} mb='4'>
								<FormLabel>Active To</FormLabel>
								<Input {...formik.getFieldProps('active_to')} type='date' />
								<FormErrorMessage>{formik.errors.active_to}</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!formik.errors.customer_group} mb='4'>
								<FormLabel>Customer Group</FormLabel>
								<Select
									placeholder='Select an option'
									{...formik.getFieldProps('customer_group')}
								>
									<option value='general'>Nesto</option>
									<option value='other'>Other</option>
								</Select>
								<FormErrorMessage>
									{formik.errors.customer_group}
								</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!formik.errors.status} mb='4'>
								<FormLabel>Status</FormLabel>
								<Select
									placeholder='Select an option'
									{...formik.getFieldProps('status')}
								>
									<option value='1'>Enabled</option>
									<option value='0'>Disabled</option>
								</Select>
								<FormErrorMessage>{formik.errors.status}</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!formik.errors.sort_order} mb='4'>
								<FormLabel>Sort Order</FormLabel>
								<Input {...formik.getFieldProps('sort_order')} type='number' />
								<FormErrorMessage>{formik.errors.sort_order}</FormErrorMessage>
							</FormControl>
						</Grid>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal}>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
