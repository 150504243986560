import {
	Box,
	Button,
	Circle,
	Divider,
	Flex,
	Grid,
	Heading,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import { FiUser } from 'react-icons/fi';
import { HiChevronDown } from 'react-icons/hi';
import { Modules } from '../../constants';
import { ModuleTypes } from '../../types';
import { logout } from '../../utils/logoutFunction';
import { setCurrentModule } from '../../utils/moduleFunctions';

interface ModulePickerProps {}

export const ModulePicker: React.FC<ModulePickerProps> = () => {
	const onModulePick = (s: ModuleTypes) => {
		setCurrentModule(s);
		window.location.reload();
	};

	return (
		<Box p='10'>
			<Flex justifyContent='end' mb='10'>
				<Menu autoSelect={false}>
					<MenuButton
						as={Button}
						leftIcon={<FiUser />}
						rightIcon={<HiChevronDown />}
						color='gray.500'
						variant='ghost'
					>
						Profile
					</MenuButton>
					<MenuList p='0'>
						<MenuItem
							onClick={logout}
							_hover={{ bg: 'white', color: 'primary.400' }}
						>
							Logout
						</MenuItem>
					</MenuList>
				</Menu>
			</Flex>
			<Heading size='lg' mb='4'>
				Select a Module to Proceed
			</Heading>
			<Divider />
			<Grid templateColumns='repeat(2, 1fr)' gap='10' mt='6'>
				{Modules.map((module) => {
					return (
						<Box
							p='10'
							boxShadow='0px 6px 12px rgba(24, 24, 24, 0.12)'
							cursor='pointer'
							key={module.key}
							onClick={() => onModulePick(module.key)}
						>
							<Circle
								size='50px'
								bg={`${module.color}.100`}
								color={`${module.color}.600`}
								mb='2'
							>
								<Icon h='6' w='6' as={module.icon} />
							</Circle>
							<Text fontWeight='bold' fontSize='lg' mb='2'>
								{module.name}
							</Text>
							<Text>{module.description}</Text>
						</Box>
					);
				})}
			</Grid>
		</Box>
	);
};
