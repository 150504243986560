import {
	Box,
	// Button,
	// Collapse,
	Divider,
	Flex,
	LinkBox,
	LinkOverlay,
	// StackDivider,
	// useDisclosure,
	VStack,
} from '@chakra-ui/react';
import React from 'react';
// import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Link } from 'react-router-dom';
// import { Modules } from '../../../../constants';
// import { ModuleTypes } from '../../../../types';
// import { setCurrentModule } from '../../../../utils/moduleFunctions';
import { NavLink } from '../../../components/NavLink';
import { NestoCart } from '../../../components/svgs/NestoCart';
import { NestoLogo } from '../../../components/svgs/NestoLogo';
import { StoresIcon } from '../../../components/svgs/StoresIcon';
import { SubTemplatesIcon } from '../../../components/svgs/SubTemplatesIcon';
import { TemplatesIcon } from '../../../components/svgs/TemplatesIcon';
import { useAppSelector } from '../../../redux/hooks';

interface SideBarProps {}

export const SideBar: React.FC<SideBarProps> = () => {
	const { isOpenSidenav } = useAppSelector((state) => state.layout);
	// const { isOpen, onToggle } = useDisclosure();
	// const onModulePick = (s: ModuleTypes) => {
	// 	setCurrentModule(s);
	// 	window.location.reload();
	// };
	return (
		<>
			<Box
				height='100vh'
				width={isOpenSidenav ? '235px' : '75px'}
				bg='white'
				boxShadow='2px 2px 16px rgba(0, 0, 0, 0.09)'
				zIndex={1}
				position='fixed'
				top='0'
				left='0'
			>
				<Flex direction='column' justifyContent='space-between' height='100%'>
					<Box flex='1'>
						<LinkBox p={isOpenSidenav ? '6' : '1'}>
							<LinkOverlay as={Link} to='/'>
								<NestoCart mr={isOpenSidenav ? '2' : '0'} color='primary.500' />
							</LinkOverlay>
							{isOpenSidenav && <NestoLogo color='primary.500' />}
						</LinkBox>
						<Divider />
						<Box mt='4'>
							<VStack alignItems='start' spacing={4}>
								<NavLink linkTo='/'>
									<Flex>
										<TemplatesIcon mr='2' />
										{isOpenSidenav && 'Templates'}
									</Flex>
								</NavLink>
								<NavLink linkTo='/sub-templates'>
									<Flex>
										<SubTemplatesIcon mr='2' />
										{isOpenSidenav && 'Sub-templates'}
									</Flex>
								</NavLink>
								<NavLink linkTo='/stores'>
									<Flex>
										<StoresIcon mr='2' />
										{isOpenSidenav && 'Stores'}
									</Flex>
								</NavLink>
							</VStack>
						</Box>
					</Box>
					<Divider />
					{/* <Box>
						{Modules.filter((m) => m.key === 'hbs').map((module) => {
							return (
								<Button
									onClick={onToggle}
									isFullWidth
									colorScheme='primary'
									variant='ghost'
									leftIcon={<module.icon />}
									rightIcon={isOpen ? <FiChevronDown /> : <FiChevronUp />}
									key={module.key}
									rounded='none'
									py='6'
									justifyContent='start'
								>
									{module.name}
								</Button>
							);
						})}
						<Divider />

						<Collapse in={isOpen} animateOpacity>
							<VStack divider={<StackDivider />}>
								{Modules.filter((m) => m.key !== 'hbs').map((module) => {
									return (
										<Button
											key={module.key}
											isFullWidth
											rounded='none'
											bg='white'
											color={`${module.color}.600`}
											leftIcon={<module.icon />}
											_hover={{ bg: 'primary.100' }}
											onClick={() => onModulePick(module.key)}
											py='6'
											justifyContent='start'
										>
											{module.name}
										</Button>
									);
								})}
							</VStack>
						</Collapse>
					</Box> */}
				</Flex>
			</Box>
		</>
	);
};
