import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Container } from './Container';
import { getCurrentModule } from './utils/moduleFunctions';
import { ZMS } from './ZMS';
import { HBS } from './HBS';

const getComponent = () => {
	const currentModule = getCurrentModule();
	if (currentModule === 'zms') {
		return ZMS;
	}
	if (currentModule === 'hbs') {
		return HBS;
	}
	return Container;
};

let Comp = getComponent();

ReactDOM.render(
	<React.StrictMode>
		<Comp />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
