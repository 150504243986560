import {
	Box,
	Flex,
	HStack,
	IconButton,
	Text,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FiTarget, FiTrash2 } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { SectionReorderElementType } from '../constants/constants';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
	moveSubTemplateInPage,
	removeContentFromPage,
} from '../redux/reducers/currentPageSlice';
import {
	moveSubTemplate,
	removeSubTemplateFromContent,
} from '../redux/reducers/subTemplateSlice';
import { SubTemplateType } from '../types/types';
import { DeleteSubTemplateWarningModal } from './DeleteSubTemplateWarningModal';
import { DragIcon } from './svgs/DragIcon';

interface SectionReorderElementProps {
	subTemplate: SubTemplateType;
}

export const SectionReorderElement: React.FC<SectionReorderElementProps> = ({
	subTemplate,
}) => {
	const currentId = subTemplate._id || subTemplate.identifier || '';
	const dispatch = useAppDispatch();
	const { currentPage } = useAppSelector((state) => state.currentPage);
	const { isSaved } = useAppSelector((state) => state.subTemplate);
	const [currentModal, setCurrentModal] = useState<'delete' | null>(null);
	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: SectionReorderElementType,
			item: subTemplate,
			canDrag: () => {
				if (isSaved.length !== 0) {
					return false;
				}
				return true;
			},
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[subTemplate, isSaved]
	);

	const { templateId } = useParams();
	const toast = useToast();

	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: SectionReorderElementType,
			drop: (item: SubTemplateType) => {
				if (item._id && item._id !== subTemplate._id) {
					dispatch(
						moveSubTemplateInPage({
							id: item._id,
							oldPosition: item.position!,
							newPosition: subTemplate.position!,
						})
					);
					return dispatch(
						moveSubTemplate({
							id: item._id,
							oldPosition: item.position!,
							newPosition: subTemplate.position!,
						})
					);
				}
				return;
			},
			collect: (monitor) => ({
				isOver: monitor.isOver(),
			}),
		}),
		[subTemplate]
	);

	const deleteFunction = async () => {
		let pageSubtemplateIdList = currentPage?.content.map((con) => {
			return con.subTemplateId;
		});
		try {
			if (pageSubtemplateIdList?.includes(subTemplate._id || '')) {
				let temp: any = Object.assign({}, currentPage);
				temp.content = temp.content
					.filter((w: any) => w.subTemplateId !== subTemplate._id!)
					.map((w: any) => {
						if (w.position > subTemplate.position!) {
							return { ...w, position: w.position - 1 };
						}
						return w;
					});
				await axios.put(`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/page`, {
					...temp,
					templateId,
				});
				dispatch(
					removeContentFromPage({
						subTemplateId: subTemplate._id!,
						position: subTemplate.position!,
					})
				);
			}

			dispatch(
				removeSubTemplateFromContent({
					id: subTemplate._id,
					identifier: subTemplate.identifier,
					position: subTemplate.position!,
				})
			);
			toast({
				position: 'top',
				status: 'success',
				description: 'Sub-template removed successfully',
			});
		} catch (err: any) {
			toast({
				position: 'top',
				status: 'error',
				description: err?.response?.data?.message || 'An error occurred',
			});
		}
		return;
	};

	const onClose = () => {
		setCurrentModal(null);
	};

	const onOpen = (s: 'delete') => {
		setCurrentModal(s);
	};

	return (
		<>
			<DeleteSubTemplateWarningModal
				endFunction={deleteFunction}
				isOpen={currentModal === 'delete'}
				onClose={onClose}
			/>
			<Box
				borderTop='2px solid'
				borderTopColor={isOver ? 'purple.600' : 'transparent'}
			>
				<Flex
					justifyContent='space-between'
					alignItems='center'
					maxWidth='100%'
					overflow='hidden'
					ref={(node) => drag(drop(node))}
					bg={isDragging ? 'gray.100' : 'white'}
				>
					<IconButton
						aria-label=''
						icon={<DragIcon />}
						variant='ghost'
						size='sm'
						_hover={{ bg: 'white', color: 'primary.400' }}
						_focus={{ outline: 'none', bg: 'white' }}
						_active={{ bg: 'transparent' }}
						ref={drag}
						cursor={isSaved.length === 0 ? 'grab' : 'not-allowed'}
					/>
					<Text
						isTruncated
						fontSize='sm'
						textAlign='left'
						flexGrow='1'
						cursor={isSaved.length === 0 ? 'grab' : 'not-allowed'}
					>
						{subTemplate.name}
					</Text>
					<HStack spacing={0}>
						<IconButton
							aria-label=''
							icon={<FiTarget />}
							variant='ghost'
							size='sm'
							color='gray.500'
							_hover={{ bg: 'white', color: 'primary.400' }}
							_focus={{ outline: 'none' }}
							onClick={() =>
								document.getElementById(currentId)?.scrollIntoView()
							}
							draggable={true}
							onDragStart={(e) => e.preventDefault()}
						/>
						<IconButton
							aria-label=''
							icon={<FiTrash2 />}
							variant='ghost'
							size='sm'
							color='gray.500'
							_hover={{ bg: 'white', color: 'primary.400' }}
							_focus={{ outline: 'none' }}
							draggable={true}
							onDragStart={(e) => e.preventDefault()}
							onClick={() => onOpen('delete')}
						/>
					</HStack>
				</Flex>
			</Box>
		</>
	);
};
