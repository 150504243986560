import {
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Alert,
	AlertIcon,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	ModalFooter,
	Button,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

interface AddCityModalFormProps {
	url: string;
	isOpen: boolean;
	onClose: () => void;
	refetchData: () => void;
}

export const AddCityModalForm: React.FC<AddCityModalFormProps> = ({
	url,
	isOpen,
	onClose,
	refetchData,
}) => {
	const initialValues = {
		name: '',
		lat: '',
		long: '',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('City is required'),
		lat: Yup.string().required('City is required'),
		long: Yup.string().required('City is required'),
	});

	const toast = useToast();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		onSubmit: async (values, { setStatus }) => {
			try {
				await axios.post(`${url}`, { ...values });
				toast({
					description: 'Item added successfully',
					status: 'success',
				});
				resetAndCloseAddModal();
				refetchData();
			} catch (err: any) {
				if (err.response.status !== 401 && err.response.status !== 420) {
					setStatus(err.response.data.message || 'An error occurred');
				}
			}
		},
	});

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Add City</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<FormControl isInvalid={!!formik.errors.name} mb='4'>
							<FormLabel>City</FormLabel>
							<Input {...formik.getFieldProps('name')} />
							<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.lat} mb='4'>
							<FormLabel>Latitude</FormLabel>
							<Input {...formik.getFieldProps('lat')} />
							<FormErrorMessage>{formik.errors.lat}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.long} mb='4'>
							<FormLabel>Longitude</FormLabel>
							<Input {...formik.getFieldProps('long')} />
							<FormErrorMessage>{formik.errors.long}</FormErrorMessage>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal}>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
